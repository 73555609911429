<template>
  <section class="interview" docprint>
    <interviewHeader :isAllElements="false" />
    <div class="interview__container" v-if="isAllowed">
      <div class="interview__containerInput title">
        <h2 class="interview__title">Formulario de entrevista</h2>
        <button class="interview__submit print" @click.prevent="onPrint" v-if="isAdmin" noprint><i class="fa fa-print"></i></button>
      </div>
      <form class="interviewForm" id="interviewForm">
        <section class="interview__card" v-if="isAdmin">
          <h4 class="interview__subtitle interview__subtitle--admin" colorTitle>Sección de coordinador</h4>
          <div class="interview__subContainerGroup interview__subContainerGroup--row">
            <label class="interview__labelTitle">
              {{ `Fecha de la entrevista: ${dateLocaleFormat(dateInterview)} a las ${timeAMPM(dateInterview)}` }}
            </label>
          </div>
          <h4 class="interview__subtitle interview__subtitle--admin">Información bancaria</h4>
          <div class="interview__radioSections" @change="onChangeRadio($event, 'hasAccountBank'), (modelHasAccountBank = isChecked($event.target.value))">
            <label class="interview__labelTitle">¿Posee cuenta en Bancolombia?</label>
            <div class="container">
              <input class="mycheck circle" type="radio" name="infoFinancial.hasAccountBank" :checked="infoAdmin.hasAccountBank === 'true'" value="true" />
              <label>Si</label>
            </div>
            <div class="container">
              <input class="mycheck circle" type="radio" name="infoFinancial.hasAccountBank" :checked="infoAdmin.hasAccountBank === 'false'" value="false" />
              <label>No</label>
            </div>
          </div>
          <div class="interview__containerInput" margin="bottom" v-if="modelHasAccountBank">
            <label class="interview__labelSingle interview__labelSingle--bank">Número de cuenta:</label>
            <input class="interview__inputWrite interview__inputWrite--single" type="text" placeholder="Ingrese el número de cuenta" v-model="accountBank" />
          </div>
          <div class="interview__radioSections" @change="onChangeRadio($event, 'hasDeclarationRent')">
            <label class="interview__labelTitle">¿Ha declarado renta?</label>
            <div class="container">
              <input class="mycheck circle" type="radio" name="infoFinancial.hasDeclarationRent" :checked="infoAdmin.hasDeclarationRent === 'true'" value="true" />
              <label>Si</label>
            </div>
            <div class="container">
              <input class="mycheck circle" type="radio" name="infoFinancial.hasDeclarationRent" :checked="infoAdmin.hasDeclarationRent === 'false'" value="false" />
              <label>No</label>
            </div>
          </div>
          <div class="interview__subContainerGroup">
            <label class="interview__labelTitle">Observaciones</label>
            <textarea class="interview__textArea" name="infoFinancial.observations" placeholder="Ingresa tu respuesta" v-model="infoAdmin.observations"></textarea>
          </div>
        </section>

        <div class="interview__container" v-if="isSectionModel">
          <section class="interview__card" id="start_require">
            <h4 class="interview__subtitle interview__subtitle--admin" v-if="isAdmin" colorTitle>Sección de modelo</h4>
            <div class="interview__subContainerGroup interview__subContainerGroup--row" v-if="!isAdmin">
              <label class="interview__labelTitle">
                {{ `Fecha de la entrevista: ${dateLocaleFormat(dateInterview)} a las ${timeAMPM(dateInterview)}` }}
              </label>
            </div>
            <h4 :class="['interview__subtitle', isAdmin ? 'interview__subtitle--admin' : '']">Información personal</h4>
            <div class="interview__subContainerGroup">
              <label class="interview__labelTitle" label="required">Nombre completo</label>
              <input class="interview__inputWrite" type="text" name="name" placeholder="Ingrese sus nombres y apellidos" v-model="model.name" @blur="validateEmpty($event)" required />
            </div>
            <div class="interview__subContainerGroup">
              <label class="interview__labelTitle">Tipo de documento</label>
              <selectInput class="interview__inputWrite" :name="'documentType'" :options="documentOptions" ref="doc_type" :elementSelect="model.documentType" />
            </div>
            <div class="interview__subContainerGroup">
              <label class="interview__labelTitle" label="required">Número de documento</label>
              <input
                class="interview__inputWrite"
                type="text"
                name="documentId"
                placeholder="Ingrese número de documento"
                @keyup="onlyNumbers($event, 'documentId')"
                @blur="validateEmpty($event)"
                v-model="model.documentId"
                required />
            </div>
            <div class="interview__subContainerGroup">
              <label class="interview__labelTitle" label="required">Fecha de nacimiento</label>
              <DMYDate name="birthDayDate" placeholder="Fecha de nacimiento" required @date="handleChangeDate" :value="model.birthDayDate" />
            </div>
            <div class="interview__containerSelects">
              <div class="interview__subContainerSelects">
                <label class="interview__labelTitle" label="required">Departamento</label>
                <selectInput
                  class="interview__inputWrite"
                  :name="'departamento'"
                  :options="getDepartamentos"
                  @selected="onSelectedDepartamento"
                  :elementSelect="model.departamento"
                  ref="select_depart"
                  placeholder=""
                  required />
              </div>
              <div class="interview__subContainerSelects">
                <label class="interview__labelTitle" label="required">Ciudad</label>
                <selectInput
                  class="interview__inputWrite"
                  :name="'cityTemp'"
                  :options="getMunicipios"
                  :elementSelect="model.cityTemp"
                  @selected="onSelectedCity"
                  ref="select_city"
                  placeholder=""
                  required />
              </div>
            </div>
            <div class="interview__subContainerGroup">
              <label class="interview__labelTitle">Dirección de residencia</label>
              <input class="interview__inputWrite" type="text" name="address" v-model="model.address" />
            </div>

            <div class="interview__subContainerGroup">
              <label class="interview__labelTitle">¿Con quién vives?</label>
              <textarea class="interview__textArea" name="infoPersonal.whoLive" placeholder="Ingresa tu respuesta" v-model="model.infoPersonal.whoLive"></textarea>
            </div>
            <div class="interview__subContainerGroup">
              <label class="interview__labelTitle">Celular</label>
              <input class="interview__inputWrite" type="text" name="phone" placeholder="Ingrese el número de celular" @keyup="onlyNumbers($event, 'phone')" v-model="model.phone" required />
            </div>
            <div class="interview__fieldSet" tittle="Hijos" :show="model.infoPersonal.hasSons === 'true' ? 'true' : 'false'">
              <div class="interview__radioSections" @change="onHasSons">
                <label class="interview__labelTitle">¿Tienes hijos?</label>
                <div class="container">
                  <input class="mycheck circle" type="radio" name="infoPersonal.hasSons" :checked="model.infoPersonal.hasSons === 'true'" value="true" />
                  <label>Si</label>
                </div>
                <div class="container">
                  <input class="mycheck circle" type="radio" name="infoPersonal.hasSons" :checked="model.infoPersonal.hasSons === 'false'" value="false" />
                  <label>No</label>
                </div>
              </div>
              <div class="interview__subContainerGroup" v-if="modelHasSons">
                <label class="interview__labelTitle">Número de hijos</label>
                <input
                  class="interview__inputWrite"
                  type="text"
                  name="infoPersonal.quantitySons"
                  placeholder="Selecciona número de hijos"
                  min="0"
                  @keyup="onlyNumbers($event, 'infoPersonal', 'quatitySons')"
                  v-model="model.infoPersonal.quantitySons" />
              </div>
              <div class="interview__radioSections" v-if="modelHasSons">
                <label class="interview__labelTitle">Desliza para indicar la edad de cada hijo</label>
                <div class="container__sons" v-for="idx in +model.infoPersonal.quantitySons || 0" :key="idx">
                  <label>Edad hijo {{ idx }}</label>
                  <div class="container__sons--data">
                    <input type="range" :id="'age' + idx" min="0" max="50" step="1" value="0" v-model="ageKids['son' + idx]" @input="showAgeSons(idx)" />
                    <output :class="'age-output' + idx" for="age" class="container__sons--age">
                      {{ model["infoPersonal"]["ageKids"] || false ? model["infoPersonal"]["ageKids"]["son" + idx] : "" }}
                    </output>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section class="interview__card">
            <h4 class="interview__subtitle">Redes sociales</h4>
            <div class="interview__socialNetworks">
              <div class="interview__subContainerGroup">
                <div class="flex interview__socialNetwork">
                  <SocialButtons :social-network="'facebook'" :username="model.facebookAccount" />
                  <label class="interview__labelTitle">FACEBOOK</label>
                </div>
                <div class="interview__inputArroba">
                  <span>@</span>
                  <input class="interview__inputWrite" type="text" name="facebookAccount" :placeholder="getDomainName('facebook')" v-model="model.facebookAccount" />
                </div>
              </div>
              <div class="interview__subContainerGroup">
                <div class="flex interview__socialNetwork">
                  <SocialButtons :social-network="'instagram'" :username="model.instagramAccount" />
                  <label class="interview__labelTitle">INSTAGRAM</label>
                </div>
                <div class="interview__inputArroba">
                  <span>@</span>
                  <input class="interview__inputWrite" type="text" name="instagramAccount" :placeholder="getDomainName('instagram')" v-model="model.instagramAccount" />
                </div>
              </div>
              <div class="interview__subContainerGroup">
                <div class="flex interview__socialNetwork">
                  <SocialButtons :social-network="'tiktok'" :username="model.tiktokAccount" />
                  <label class="interview__labelTitle">TIKTOK</label>
                </div>
                <div class="interview__inputArroba">
                  <span>@</span>
                  <input class="interview__inputWrite" type="text" name="facebookAccount" :placeholder="getDomainName('tiktok')" v-model="model.tiktokAccount" />
                </div>
              </div>
              <div class="interview__subContainerGroup">
                <div class="flex interview__socialNetwork">
                  <SocialButtons :social-network="'onlyfans'" :username="model.onlyfansAccount" />
                  <label class="interview__labelTitle">ONLYFANS</label>
                </div>
                <div class="interview__inputArroba">
                  <span>@</span>
                  <input class="interview__inputWrite" type="text" name="instagramAccount" :placeholder="getDomainName('onlyfans')" v-model="model.onlyfansAccount" />
                </div>
              </div>
            </div>
          </section>
          <section class="interview__card">
            <h4 class="interview__subtitle">Educación</h4>
            <div class="interview__subContainerGroup">
              <label class="interview__labelTitle">Nivel académico</label>
              <selectInput class="interview__inputWrite" :name="'infoPersonal.levelStudy'" :options="getLevelStudy" :elementSelect="model.infoPersonal.levelStudy" />
            </div>
            <div class="interview__radioSections" @change="onHasStudy">
              <label class="interview__labelTitle">¿Estudias actualmente?</label>
              <div class="container">
                <input class="mycheck circle" type="radio" name="infoPersonal.hasStudy" :checked="model.infoPersonal.hasStudy === 'true'" value="true" />
                <label>Si</label>
              </div>
              <div class="container">
                <input class="mycheck circle" type="radio" name="infoPersonal.hasStudy" :checked="model.infoPersonal.hasStudy === 'false'" value="false" />
                <label>No</label>
              </div>
            </div>
            <div class="interview__containerInput" v-if="modelHasStudy">
              <label class="interview__labelSingle">Horario:</label>
              <input
                class="interview__inputWrite interview__inputWrite--single"
                type="text"
                name="infoPersonal.schudeleStudy"
                placeholder="Ingrese el horario"
                v-model="model.infoPersonal.schudeleStudy" />
            </div>
          </section>

          <section class="interview__card">
            <h4 class="interview__subtitle">Información laboral</h4>
            <div class="interview__radioSections" @change="onHasProfesion">
              <label class="interview__labelTitle">Trabajas Actualmente</label>
              <div class="container">
                <input class="mycheck circle" type="radio" name="infoWork.hasWork" :checked="model.infoWork.hasWork === 'true'" value="true" />
                <label>Si</label>
              </div>
              <div class="container">
                <input class="mycheck circle" type="radio" name="infoWork.hasWork" :checked="model.infoWork.hasWork === 'false'" value="false" />
                <label>No</label>
              </div>
            </div>
            <div class="interview__containerInput" margin="bottom" v-if="modelHasProfesion">
              <label class="interview__labelSingle">Ocupación:</label>
              <input class="interview__inputWrite interview__inputWrite--single" type="text" name="infoWork.profesion" placeholder="Ingrese la ocupación actual" v-model="model.infoWork.profesion" />
            </div>
            <div class="interview__radioSections">
              <label class="interview__labelTitle">¿Tienes disponibilidad de 8 horas diarias de Lunes a Sábado?</label>
              <div class="container">
                <input class="mycheck circle" type="radio" name="infoWork.hasDisponiblity" :checked="model.infoWork.hasDisponiblity === 'true'" value="true" />
                <label>Si</label>
              </div>
              <div class="container">
                <input class="mycheck circle" type="radio" name="infoWork.hasDisponiblity" :checked="model.infoWork.hasDisponiblity === 'false'" value="false" />
                <label>No</label>
              </div>
            </div>
            <div class="interview__radioSections" id="timeAvailability">
              <label class="interview__labelTitle">
                Selecciona el horario que más te favorezca. En caso de marcar todas, selecciona un numero del 1 al 3, siendo 1 tu primera opción y 3 tu última opción.
              </label>
              <div class="container">
                <input
                  class="mycheck squirtle mycheck--checkbox"
                  name="infoWork.timeAvailabilityMorning"
                  type="checkbox"
                  :checked="model.infoWork.timeAvailabilityMorning === 'morning'"
                  value="morning"
                  @change.stop="onChangeTimeAvailability($event, 'morning', 'timeAvailabilityPriorityMorning')" />
                <label class="interview__labelCheck">06:00am a 02:00pm</label>
                <select
                  name="infoWork.timeAvailabilityPriorityMorning"
                  v-model="model.infoWork.timeAvailabilityPriorityMorning"
                  :disabled="isAvailablePriority.morning"
                  :required="!isAvailablePriority.morning">
                  <option v-for="option in [1, 2, 3]" :key="option" :value="option">
                    {{ option }}
                  </option>
                </select>
              </div>
              <div class="container">
                <input
                  class="mycheck squirtle mycheck--checkbox"
                  name="infoWork.timeAvailabilityLate"
                  type="checkbox"
                  :checked="model.infoWork.timeAvailabilityLate === 'late'"
                  value="late"
                  @change.stop="onChangeTimeAvailability($event, 'late', 'timeAvailabilityPriorityLate')" />
                <label class="interview__labelCheck">02:00pm a 10:00pm</label>
                <select name="infoWork.timeAvailabilityPriorityLate" v-model="model.infoWork.timeAvailabilityPriorityLate" :disabled="isAvailablePriority.late" :required="!isAvailablePriority.late">
                  <option v-for="option in [1, 2, 3]" :key="option" :value="option">
                    {{ option }}
                  </option>
                </select>
              </div>
              <div class="container">
                <input
                  class="mycheck squirtle mycheck--checkbox"
                  name="infoWork.timeAvailabilityNight"
                  type="checkbox"
                  :checked="model.infoWork.timeAvailabilityNight === 'night'"
                  value="night"
                  @change.stop="onChangeTimeAvailability($event, 'night', 'timeAvailabilityPriorityNight')" />
                <label class="interview__labelCheck">10:00pm a 06:00am</label>
                <select
                  name="infoWork.timeAvailabilityPriorityNight"
                  v-model="model.infoWork.timeAvailabilityPriorityNight"
                  :disabled="isAvailablePriority.night"
                  :required="!isAvailablePriority.night">
                  <option v-for="option in [1, 2, 3]" :key="option" :value="option">
                    {{ option }}
                  </option>
                </select>
              </div>
            </div>
            <div class="interview__subContainerGroup">
              <label class="interview__labelTitle">¿Cual ha sido tu mejor salario? (En pesos colombianos).</label>
              <div class="interview__containerInput">
                <input class="interview__inputWrite interview__inputWrite--single" type="text" placeholder="Ingrese valor" v-model="bestSalaryCOP" />
              </div>
            </div>
            <div class="interview__subContainerGroup">
              <label class="interview__labelTitle">Cuál sería tu salario mensual ideal al cabo de:</label>
              <div class="interview__containerInput">
                <label class="interview__labelSingle interview__labelText">2 meses:</label>
                <input class="interview__inputWrite interview__inputWrite--single" type="text" placeholder="Ingrese valor" v-model="salaryTwoMonth" />
              </div>
              <div class="interview__containerInput">
                <label class="interview__labelSingle interview__labelText">6 meses:</label>
                <input class="interview__inputWrite interview__inputWrite--single" type="text" placeholder="Ingrese valor" v-model="salarySixMonth" />
              </div>
              <div class="interview__containerInput">
                <label class="interview__labelSingle interview__labelText">1 año:</label>
                <input class="interview__inputWrite interview__inputWrite--single" type="text" placeholder="Ingrese valor" v-model="salaryOneYear" />
              </div>
            </div>
            <div class="interview__fieldSet" id="interview__modelExperiencie" tittle="Experiencia" :show="model.hasWebcam === 'yes' ? 'true' : 'false'">
              <div class="interview__radioSections" @change="onHasExperiencie">
                <label class="interview__labelTitle">¿Has trabajado como modelo webcam?</label>
                <div class="container">
                  <input class="mycheck circle" type="radio" name="infoWork.hasExperienceWebCam" :checked="model.hasWebcam === 'yes'" value="true" />
                  <label>Si</label>
                </div>
                <div class="container">
                  <input class="mycheck circle" type="radio" name="infoWork.hasExperienceWebCam" :checked="model.hasWebcam === 'no'" value="false" />
                  <label>No</label>
                </div>
              </div>
              <div class="interview__subContainerGroup" v-if="modelHasExperiencie">
                <span class="interview__subContainerInputDouble">
                  <label class="interview__labelTitle">Nombre del estudio</label>
                  <label class="interview__labelTitle">¿Motivo por el cual decides retirarte?</label>
                </span>
                <InputAddDouble
                  class="interview__experienceStudyModel"
                  :elementsOne="modelStudyName"
                  :elementsTwo="modelReasonBye"
                  :key="inputsAdd"
                  @addValue="addValueStudyName"
                  ref="double_experiencestudy" />
              </div>
              <div class="interview__subContainerGroup" v-if="modelHasExperiencie">
                <span class="interview__subContainerInputDouble">
                  <label class="interview__labelTitle">Páginas en las que has trabajado</label>
                  <label class="interview__labelTitle">Nombre de modelo en cada una</label>
                </span>
                <InputAddDouble
                  class="interview__experiencePageModel"
                  :elementsOne="modelPagesWorked"
                  :elementsTwo="modelUserWorked"
                  :key="inputsAdd"
                  @addValue="addValueModelsPages"
                  ref="double_experiencepage" />
              </div>
              <div class="interview__subContainerGroup" v-if="modelHasExperiencie">
                <label class="interview__labelTitle">¿Cuánto tiempo de experiencia tienes como modelo webcam?</label>
                <div class="interview__containerBasic">
                  <input
                    class="interview__inputYear"
                    type="text"
                    name="infoWork.timeExperenceWebCam"
                    placeholder=""
                    v-model="model.infoWork.timeExperenceWebCam"
                    @keyup="onlyNumbers($event, 'infoWork', 'timeExperenceWebCam')" />
                  <label>Año(s)</label>
                </div>
                <label class="interview__labelTitle">Cuál ha sido tu mejor:</label>
                <div class="interview__containerInput">
                  <label class="interview__labelSingle interview__labelText">Semana:</label>
                  <input class="interview__inputWrite interview__inputWrite--single" type="text" placeholder="Ingrese valor" v-model="bestSalaryWeek" />
                </div>
                <div class="interview__containerInput">
                  <label class="interview__labelSingle interview__labelText">Quincena:</label>
                  <input class="interview__inputWrite interview__inputWrite--single" type="text" placeholder="Ingrese valor" v-model="bestSalaryMonth" />
                </div>
                <div class="interview__containerInput">
                  <label class="interview__labelSingle interview__labelText">Mes:</label>
                  <input class="interview__inputWrite interview__inputWrite--single" type="text" placeholder="Ingrese valor" v-model="bestSalaryYear" />
                </div>
              </div>
            </div>
            <div class="interview__radioSections">
              <label class="interview__labelTitle">¿Por cuánto tiempo te ves haciendo modelaje webcam?</label>
              <div class="container">
                <input class="mycheck circle" type="radio" name="infoWork.expectModelWebCam" :checked="model.infoWork.expectModelWebCam === 'betweenOneToThree'" value="betweenOneToThree" />
                <label>Entre 1 a 3 años</label>
              </div>
              <div class="container">
                <input class="mycheck circle" type="radio" name="infoWork.expectModelWebCam" :checked="model.infoWork.expectModelWebCam === 'betweenThreeToFive'" value="betweenThreeToFive" />
                <label>Entre 3 a 5 años</label>
              </div>
              <div class="container">
                <input class="mycheck circle" type="radio" name="infoWork.expectModelWebCam" :checked="model.infoWork.expectModelWebCam === 'mostFiveYear'" value="mostFiveYear" />
                <label>Más de 5 años</label>
              </div>
            </div>
            <div class="interview__radioSections" @change="onHasPrivacity">
              <label class="interview__labelTitle"> De ser seleccionada, desearías bloquear Colombia u otro país o no tienes inconveniente con la privacidad </label>
              <div class="container">
                <input class="mycheck circle" type="radio" name="infoWork.hasPrivacity" :checked="model.infoWork.hasPrivacity === 'true'" value="true" />
                <label>Si</label>
              </div>
              <div class="container">
                <input class="mycheck circle" type="radio" name="infoWork.hasPrivacity" :checked="model.infoWork.hasPrivacity === 'false'" value="false" />
                <label>No</label>
              </div>
            </div>
            <div class="interview__subContainerGroup" v-if="modelHasPrivacity">
              <label class="interview__labelTitle">Si deseas bloquear otro país, por favor ingrésalo</label>
              <inputAdd class="interview__countryPrivacity" :elements="modelCountryPrivacity" :key="inputsAdd" @addValue="addCountryPrivacity" />
            </div>
            <div class="interview__radioSections">
              <label class="interview__labelTitle"> Tu familia, pareja sentimental o personas cercanas a tu entorno tiene algún inconveniente con que te desempeñes como modelo webcam: </label>
              <div class="container">
                <input class="mycheck circle" type="radio" name="infoWork.familyPermition" :checked="model.infoWork.familyPermition === 'true'" value="true" />
                <label>Si</label>
              </div>
              <div class="container">
                <input class="mycheck circle" type="radio" name="infoWork.familyPermition" :checked="model.infoWork.familyPermition === 'false'" value="false" />
                <label>No</label>
              </div>
            </div>
          </section>
          <section class="interview__card">
            <h4 class="interview__subtitle">Información adicional</h4>
            <div class="interview__radioSectionsTable">
              <label class="interview__labelTitle">Del 1 - 10 ¿como te calificas? 1 es el puntaje más bajo y 10 el más alto</label>
              <div class="interview__radioSectionRow interview__radioSectionRow--head">
                <span class="firstColumn firstColumn--head"></span>
                <div class="interview__radioSectionRowCheck">
                  <span class="head" v-for="id in 10" :key="id">{{ id }}</span>
                </div>
              </div>
              <div class="interview__radioSectionRow">
                <span class="firstColumn">Actitud</span>
                <div class="interview__radioSectionRowCheck">
                  <input class="mycheck circle" type="radio" v-for="id in 10" :key="id" name="infoAdditional.attitud" :checked="model.infoAdditional.attitud == id" :value="id" />
                </div>
              </div>
              <div class="interview__radioSectionRow">
                <span class="firstColumn">Paciencia</span>
                <div class="interview__radioSectionRowCheck">
                  <input class="mycheck circle" type="radio" v-for="id in 10" :key="id" name="infoAdditional.patience" :checked="model.infoAdditional.patience == id" :value="id" />
                </div>
              </div>
              <div class="interview__radioSectionRow">
                <span class="firstColumn">Disciplina</span>
                <div class="interview__radioSectionRowCheck">
                  <input class="mycheck circle" type="radio" v-for="id in 10" :key="id" name="infoAdditional.discipline" :checked="model.infoAdditional.discipline == id" :value="id" />
                </div>
              </div>
              <div class="interview__radioSectionRow">
                <span class="firstColumn">Honestidad</span>
                <div class="interview__radioSectionRowCheck">
                  <input class="mycheck circle" type="radio" v-for="id in 10" :key="id" name="infoAdditional.honesty" :checked="model.infoAdditional.honesty == id" :value="id" />
                </div>
              </div>
              <div class="interview__radioSectionRow">
                <span class="firstColumn">Puntualidad</span>
                <div class="interview__radioSectionRowCheck">
                  <input class="mycheck circle" type="radio" v-for="id in 10" :key="id" name="infoAdditional.puntuality" :checked="model.infoAdditional.puntuality == id" :value="id" />
                </div>
              </div>
              <div class="interview__radioSectionRow">
                <span class="firstColumn">Compromiso</span>
                <div class="interview__radioSectionRowCheck">
                  <input class="mycheck circle" type="radio" v-for="id in 10" :key="id" name="infoAdditional.commitment" :checked="model.infoAdditional.commitment == id" :value="id" />
                </div>
              </div>
              <div class="interview__radioSectionRow">
                <span class="firstColumn">Apariencia física</span>
                <div class="interview__radioSectionRowCheck">
                  <input class="mycheck circle" type="radio" v-for="id in 10" :key="id" name="infoAdditional.appear" :checked="model.infoAdditional.appear == id" :value="id" />
                </div>
              </div>
            </div>
            <div class="interview__radioSections" @change="onHasSurgery">
              <label class="interview__labelTitle">¿Has pensado en hacerte cirugía estética?</label>
              <div class="container">
                <input class="mycheck circle" type="radio" name="infoAdditional.hasThinkSurgery" :checked="model.infoAdditional.hasThinkSurgery === 'true'" value="true" />
                <label>Si</label>
              </div>
              <div class="container">
                <input class="mycheck circle" type="radio" name="infoAdditional.hasThinkSurgery" :checked="model.infoAdditional.hasThinkSurgery === 'false'" value="false" />
                <label>No</label>
              </div>
            </div>
            <div class="interview__subContainerGroup" v-if="modelHasSurgery">
              <label class="interview__labelTitle">¿Qué te realizarías?</label>
              <input class="interview__inputWrite" type="text" name="infoAdditional.possiblySurgery" placeholder="Ingresa tu respuesta" v-model="model.infoAdditional.possiblySurgery" />
            </div>
            <div class="interview__subContainerGroup">
              <label class="interview__labelTitle">¿Qué es lo que más te gusta de tu cuerpo?</label>
              <input class="interview__inputWrite" type="text" name="infoAdditional.mostLikeBody" placeholder="Ingresa tu respuesta" v-model="model.infoAdditional.mostLikeBody" />
            </div>
            <div class="interview__subContainerGroup">
              <label class="interview__labelTitle">¿Qué es lo que no te gusta de tu cuerpo?</label>
              <input class="interview__inputWrite" type="text" name="infoAdditional.lessLikeBody" placeholder="Ingresa tu respuesta" v-model="model.infoAdditional.lessLikeBody" />
            </div>
            <div class="interview__subContainerGroup">
              <label class="interview__labelTitle">¿Qué te motiva a buscar sobre la industria del modelaje webcam?</label>
              <textarea class="interview__textArea" name="infoAdditional.motivateWebCam" placeholder="Ingresa tu respuesta" v-model="model.infoAdditional.motivateWebCam"></textarea>
            </div>
            <div class="interview__containerMultipleInput">
              <label class="interview__labelTitle">Cuáles son sus aspiraciones o metas en:</label>
              <div class="interview__containerInput">
                <label class="interview__labelSingle interview__labelText goals">1 año:</label>
                <input
                  class="interview__inputWrite interview__inputWrite--single"
                  type="text"
                  name="infoAdditional.aspirationOneYear"
                  placeholder="Ingrese tu respuesta"
                  v-model="model.infoAdditional.aspirationOneYear" />
              </div>

              <div class="interview__containerInput">
                <label class="interview__labelSingle interview__labelText goals">3 años:</label>
                <input
                  class="interview__inputWrite interview__inputWrite--single"
                  type="text"
                  name="infoAdditional.aspirationThreeYear"
                  placeholder="Ingrese tu respuesta"
                  v-model="model.infoAdditional.aspirationThreeYear" />
              </div>
              <div class="interview__containerInput">
                <label class="interview__labelSingle interview__labelText goals">5 años:</label>
                <input
                  class="interview__inputWrite interview__inputWrite--single"
                  type="text"
                  name="infoAdditional.aspirationFiveYear"
                  placeholder="Ingrese tu respuesta"
                  v-model="model.infoAdditional.aspirationFiveYear" />
              </div>
            </div>
            <div class="interview__subContainerGroup">
              <label class="interview__labelTitle">¿Por qué te gustaría ser parte de Models1A?</label>
              <textarea class="interview__textArea" name="infoAdditional.whyLikeWebCam" placeholder="Ingresa tu respuesta" v-model="model.infoAdditional.whyLikeWebCam"></textarea>
            </div>
            <div class="interview__subContainerGroup">
              <label class="interview__labelTitle">¿Cómo te enteraste de la agencia?</label>
              <textarea class="interview__textArea" name="howKnow" placeholder="Ingrese su respuesta o recomendación por alguién del estudio" v-model="model.howKnow"></textarea>
              <div class="interview__containerInput special" margin="top">
                <div class="interview__containerCheck">
                  <input class="mycheck squirtle mycheck--checkbox" type="checkbox" v-model="isRecommended" />
                  <label class="interview__checkInput interview__labelSingle">Recomendada por:</label>
                </div>
                <input
                  class="interview__inputWrite interview__inputWrite--single"
                  v-if="isRecommended"
                  type="text"
                  name="infoAdditional.recommended"
                  placeholder="¿Por quién?"
                  v-model="model.infoAdditional.recommended" />
              </div>
            </div>
            <div class="interview__subContainerGroup">
              <label class="interview__labelTitle">¿Qué modelos conoces de Models 1A?</label>

              <inputAdd :elements="modelsKnowModels1a" :key="inputsAdd" @addValue="addValueStudyName" ref="double_experiencestudy" />

              <div class="interview__containerInput special" margin="top">
                <div class="interview__containerCheck">
                  <input class="mycheck squirtle mycheck--checkbox" type="checkbox" v-model="isKnowModel" />
                  <label class="interview__checkInput interview__labelSingle">¿Con qué modelo te identificas?</label>
                </div>
                <input
                  class="interview__inputWrite interview__inputWrite--single"
                  v-if="isKnowModel"
                  type="text"
                  name="infoAdditional.knowModel"
                  placeholder="Nombre"
                  v-model="model.infoAdditional.knowModel" />
              </div>
            </div>
            <div class="interview__subContainerGroup">
              <label class="interview__labelTitle">Comentarios</label>
              <textarea class="interview__textArea" name="infoAdditional.comments" placeholder="Ingresa tu respuesta" v-model="model.infoAdditional.comments"></textarea>
            </div>
          </section>
        </div>
        <div class="interview__containerInput containterButtons">
          <input class="interview__submit inputSubmit" type="submit" :value="isAdmin ? 'Guardar' : 'Enviar'" @click.prevent="onSubmit" noprint />
          <input class="interview__submit inputCancel" type="button" value="Cancelar" @click.prevent="onCancel" noprint />
        </div>
        <a class="interview__link" @click="viewSectionModel" v-if="isAdmin">
          <iconic name="plus" v-if="!isSectionModel" />
          <iconic name="minus" v-else />
          <span>
            {{ `${isSectionModel ? "Ocultar" : "Mostrar"} formulario de modelo` }}
          </span>
        </a>
      </form>
    </div>
  </section>
</template>

<script>
  import Vue from "vue";
  import Swal from "sweetalert2";
  import store from "../../store/index.js";
  import swal from "@/assets/js/swalAlerts";
  import selectInput from "@/components/inputs/Select.vue";
  import inputAdd from "@/components/inputs/InputAdd.vue";
  import InputAddDouble from "@/components/inputs/InputAddDouble.vue";
  import SocialButtons from "@/components/widgets/SocialButtons.vue";
  import departamentos from "@/assets/js/departamentosColombia.json";
  import api from "@/api/index";
  import interviewHeader from "@/components/web/Header.vue";
  import global from "@/assets/js/global";
  import iconic from "@/plugins/iconic/iconic.vue";
  import { dictionary } from "@/assets/js/dictionary";
  export default {
    components: { DMYDate: () => import("../inputs/DMYDate"), selectInput, inputAdd, InputAddDouble, interviewHeader, SocialButtons, iconic },
    filters: {
      currency: {
        read: function (value) {
          return "$" + value.toFixed(2);
        },
        write: function (value) {
          var number = +value.replace(/[^\d.]/g, "");
          return isNaN(number) ? 0 : number;
        },
      },
    },
    data: function () {
      return {
        getLevelStudy: [
          { value: "", text: "Seleccione nivel académico" },
          { value: "primaria", text: "Primaria" },
          { value: "secundaria", text: "Secundaria" },
          { value: "tecnologo", text: "Técnico/Tecnólogo" },
          { value: "universitario", text: "Universitario" },
        ],
        departamento: null,
        dateInterview: "",
        infoAdmin: {},
        isAllowed: false,
        isAdmin: false,
        isRecommended: false,
        isKnowModel: false,
        isSectionModel: false,
        model: {},
        modelHasStudy: false,
        modelHasSons: false,
        modelHasProfesion: false,
        modelHasSurgery: false,
        modelHasExperiencie: false,
        modelHasPrivacity: false,
        modelHasAccountBank: false,
        modelCountryPrivacity: [],
        modelStudyName: [],
        modelsKnowModels1a: [],
        modelReasonBye: [],
        modelPagesWorked: [],
        modelUserWorked: [],
        inputsAdd: 1,
        isAvailablePriority: { morning: true, late: true, night: true },
        accountBank: "",
        bestSalaryCOP: "",
        bestSalaryMonth: "",
        bestSalaryWeek: "",
        bestSalaryYear: "",
        salaryOneYear: "",
        salarySixMonth: "",
        salaryTwoMonth: "",
        ageKids: {},
      };
    },
    computed: {
      documentOptions() {
        return Object.keys(this.$global.dictionary.documentType).map((key) => {
          return {
            value: key,
            text: this.$global.dictionary.documentType[key],
          };
        });
      },
      getDepartamentos() {
        const departamento = departamentos.map((departamento) => ({ value: departamento.departamento, text: departamento.departamento }));
        return [{ value: "", text: "Selecciona departamento" }, ...departamento];
      },
      getMunicipios() {
        if (this.departamento) {
          const depatartamentos = departamentos
            .find((dpt) => dpt.departamento === this.departamento)
            .ciudades.map((ciudad) => ({ value: this.$global.dictionary.cities[ciudad] ?? ciudad, text: ciudad }));
          return [{ value: "", text: "Selecciona ciudad" }, ...depatartamentos];
        }
        return [{ value: "", text: "Selecciona ciudad" }];
      },
      getPhotos() {
        if (this.model.photos) {
          return this.model.photos.map((photo) => ({ src: photo.link, name: photo.name }));
        }
        return [{ src: "", name: "" }];
      },
    },
    watch: {
      accountBank: function (newValue, oldValue) {
        if (newValue) {
          if (newValue?.length > 0) {
            if (!this.$validate.validNumber(newValue) || newValue.length > 11) {
              this.accountBank = oldValue;
              return;
            }
          }
        }
      },
      bestSalaryMonth: function (newValue, oldValue) {
        this.handlerCurrency(newValue, oldValue, "bestSalaryMonth");
      },
      bestSalaryWeek: function (newValue, oldValue) {
        this.handlerCurrency(newValue, oldValue, "bestSalaryWeek");
      },
      bestSalaryYear: function (newValue, oldValue) {
        this.handlerCurrency(newValue, oldValue, "bestSalaryYear");
      },
      salaryOneYear: function (newValue, oldValue) {
        this.handlerCurrency(newValue, oldValue, "salaryOneYear");
      },
      salarySixMonth: function (newValue, oldValue) {
        this.handlerCurrency(newValue, oldValue, "salarySixMonth");
      },
      salaryTwoMonth: function (newValue, oldValue) {
        this.handlerCurrency(newValue, oldValue, "salaryTwoMonth");
      },
      bestSalaryCOP: function (newValue, oldValue) {
        this.handlerCurrency(newValue, oldValue, "bestSalaryCOP");
      },
    },
    methods: {
      handleChangeDate(date) {
        this.model.birthDayDate = date;
      },
      getDomainName(socialNetwork) {
        const social = dictionary.networksInfo[socialNetwork];
        if (!social) return "/Tu Cuenta";

        return social.baseUrl.split(`https://www.`).pop();
      },
      showAgeSons(id) {
        const age = document.querySelector("#age" + id);
        const output = document.querySelector(".age-output" + id);
        output.textContent = age.value;
      },

      async onSubmit() {
        let objSwalError = {
          icon: "error",
          confirmButtonText: "Aceptar",
          timerProgressBar: true,
          confirmButtonColor: "#bd0909",
        };
        const response = await Swal.fire({
          text: "¿Está seguro de enviar esta información?",
          icon: "question",
          showCancelButton: true,
          confirmButtonText: "Si",
          cancelButtonText: "No",
          timerProgressBar: true,
          confirmButtonColor: "#bd0909",
          cancelButtonColor: "##515151",
        });
        if (response.isDismissed) return false;

        if (this.$validate.validateForm("#interviewForm")) {
          const dataSend = new FormData(document.getElementById("interviewForm"));
          if (this.isSectionModel) {
            const modelStudies = document.querySelectorAll(".interview__experienceStudyModel .inputAddDouble__input");
            const modelPages = document.querySelectorAll(".interview__experiencePageModel .inputAddDouble__input");
            const countryPrivacity = document.querySelector(".interview__countryPrivacity .inputAdd__input");

            // Validacion de los horarios disponibles para laborar
            document.querySelectorAll('[name^="infoWork.timeAvailability"]').forEach((item) => item.classList.remove("error"));
            const { timeAvailabilityPriorityMorning, timeAvailabilityPriorityLate, timeAvailabilityPriorityNight } = this.model.infoWork;
            if (timeAvailabilityPriorityMorning || timeAvailabilityPriorityLate || timeAvailabilityPriorityNight) {
              if (
                (timeAvailabilityPriorityMorning && timeAvailabilityPriorityMorning === timeAvailabilityPriorityLate) ||
                (timeAvailabilityPriorityMorning && timeAvailabilityPriorityMorning === timeAvailabilityPriorityNight) ||
                (timeAvailabilityPriorityLate && timeAvailabilityPriorityLate === timeAvailabilityPriorityNight)
              ) {
                if (timeAvailabilityPriorityMorning === timeAvailabilityPriorityLate) {
                  document.querySelector('[name="infoWork.timeAvailabilityPriorityMorning"]').classList.add("error");
                  document.querySelector('[name="infoWork.timeAvailabilityPriorityLate"]').classList.add("error");
                }

                if (timeAvailabilityPriorityMorning === timeAvailabilityPriorityNight) {
                  document.querySelector('[name="infoWork.timeAvailabilityPriorityMorning"]').classList.add("error");
                  document.querySelector('[name="infoWork.timeAvailabilityPriorityNight"]').classList.add("error");
                }

                if (timeAvailabilityPriorityLate === timeAvailabilityPriorityNight) {
                  document.querySelector('[name="infoWork.timeAvailabilityPriorityLate"]').classList.add("error");
                  document.querySelector('[name="infoWork.timeAvailabilityPriorityNight"]').classList.add("error");
                }

                objSwalError.text = "No puedes repetir la prioridad de los horarios.";
                const { isConfirmed } = await Swal.fire(objSwalError);
                if (isConfirmed) {
                  setTimeout(() => {
                    this.$global.scrollToEl({ el: "#timeAvailability", behavior: "smooth", topgap: 150 });
                  }, 300);
                }
                return;
              }
            }

            if (modelStudies && modelStudies.length === 2) {
              if (modelStudies[0].value && modelStudies[1].value) {
                this.modelStudyName.push(modelStudies[0].value);
                this.modelReasonBye.push(modelStudies[1].value);
              } else if ((modelStudies[0].value && !modelStudies[1].value) || (!modelStudies[0].value && modelStudies[1].value)) {
                objSwalError.text = "En la sección de experiencia, falta información. Por favor ingrese la información correctamente.";
              }
            }

            if (this.modelsKnowModels1a.length > 0) {
              dataSend.append("infoAdditional.modelsKnowModels1a", this.modelsKnowModels1a.join(","));
            }

            if (modelPages && modelPages.length === 2) {
              if (modelPages[0].value && modelPages[1].value) {
                this.modelPagesWorked.push(modelPages[0].value);
                this.modelUserWorked.push(modelPages[1].value);
              } else if ((modelPages[0].value && !modelPages[1].value) || (!modelPages[0].value && modelPages[1].value)) {
                objSwalError.text = "En la sección de experiencia, falta información. Por favor ingrese la información correctamente.";
                if (modelStudies[0].value && modelStudies[1].value) {
                  this.modelStudyName.pop();
                  this.modelReasonBye.pop();
                }
              }
            }
            if (objSwalError.text) {
              const { isConfirmed } = await Swal.fire(objSwalError);
              if (isConfirmed) {
                if (modelPages[0].value && modelPages[1].value) {
                  this.modelPagesWorked.pop();
                  this.modelUserWorked.pop();
                }
                this.$refs.double_experiencestudy.verifyDataError();
                this.$refs.double_experiencepage.verifyDataError();
                setTimeout(() => {
                  this.$global.scrollToEl({ el: "#interview__modelExperiencie", behavior: "smooth", topgap: 50 });
                }, 300);
              }
              return;
            }

            if (countryPrivacity && countryPrivacity.value) {
              this.modelCountryPrivacity.push(countryPrivacity.value);
            }

            dataSend.append("infoWork.countryPrivacity", JSON.stringify(this._validateUndefined(this.modelCountryPrivacity)));
            dataSend.append("infoWork.modelStudyName", JSON.stringify(this._validateUndefined(this.modelStudyName)));
            dataSend.append("infoWork.modelReasonBye", JSON.stringify(this._validateUndefined(this.modelReasonBye)));
            dataSend.append("infoWork.modelPagesWorked", JSON.stringify(this._validateUndefined(this.modelPagesWorked)));
            dataSend.append("infoWork.modelUserWorked", JSON.stringify(this._validateUndefined(this.modelUserWorked)));
            dataSend.append("interviewDateSuccess", this.dateInterview);
            dataSend.append("hasWebcam", this.modelHasExperiencie ? "yes" : "no");
            dataSend.append("infoWork.bestSalaryMonth", this.toNumber(this.bestSalaryMonth));
            dataSend.append("infoWork.bestSalaryWeek", this.toNumber(this.bestSalaryWeek));
            dataSend.append("infoWork.bestSalaryYear", this.toNumber(this.bestSalaryYear));
            dataSend.append("infoWork.salaryOneYear", this.toNumber(this.salaryOneYear));
            dataSend.append("infoWork.salarySixMonth", this.toNumber(this.salarySixMonth));
            dataSend.append("infoWork.salaryTwoMonth", this.toNumber(this.salaryTwoMonth));
            dataSend.append("infoWork.bestSalaryCOP", this.toNumber(this.bestSalaryCOP));
            dataSend.append("isInterview", "on");
            dataSend.append("isSelected", "true");
            const tiktokAccount = this._validateUndefined(this.model.tiktokAccount);
            dataSend.append("tiktokAccount", tiktokAccount);
            dataSend.append("onlyfansAccount", this._validateUndefined(this.model.onlyfansAccount));
          }
          if (this.modelHasSons) dataSend.append("infoPersonal.ageKids", JSON.stringify(this.ageKids || {}));
          dataSend.append("infoFinancial.hasAccountBank", this._validateUndefined(this.infoAdmin.hasAccountBank));
          dataSend.append("infoFinancial.accountBank", this.modelHasAccountBank ? this._validateUndefined(this.accountBank) : "");
          dataSend.append("infoFinancial.hasDeclarationRent", this._validateUndefined(this.infoAdmin.hasDeclarationRent));
          dataSend.append("infoFinancial.observations", this._validateUndefined(this.infoAdmin.observations));

          // Quitar el espacio al final de los campos
          for (const [key, value] of dataSend.entries()) {
            dataSend.set(key, value.trim());
          }

          swal.dataSending();
          let endpoint = "";
          if (this.isAdmin) endpoint = `${this.$host}interviews/${this.model._id}`;
          else endpoint = `${this.$host}interviews/${this.model._id}?isuser=true`;

          const { data } = await api.post(endpoint, dataSend);
          Swal.close();
          this.inputsAdd++;
          if (!data.error) {
            if (this.model.documentId && this.model.birthDayDate) {
              store.commit("emitToCloud", { message: "emitToCloud", modelo: this.model, event: "interviewFinished" });
            }
            await Swal.fire({
              text: "Información enviada",
              icon: "success",
              confirmButtonText: "Aceptar",
              timerProgressBar: true,
              confirmButtonColor: "#bd0909",
            });
            if (!this.isAdmin) {
              localStorage.removeItem("modelInterview");
              this.$router.replace({ name: "interviewLogin" });
              return;
            }
          } else {
            objSwalError.text = data.error;
            Swal.fire(objSwalError);
          }
        } else {
          objSwalError.text = "Por favor ingrese los campos requeridos.";
          const { isConfirmed } = await Swal.fire(objSwalError);
          if (isConfirmed) {
            setTimeout(() => {
              this.$global.scrollToEl({ el: "#start_require", behavior: "smooth", topgap: 50 });
            }, 300);
          }

          const inputs = document.querySelectorAll("*[required]");
          inputs.forEach((el) => {
            if (!el.value) {
              el.classList.add("error");
            }
          });
        }
      },
      async onCancel() {
        const { isConfirmed } = await swal.dataSendConfirm({
          message: "¿Quieres salir del formulario de entrevista?",
          buttonTextYes: "Si",
          buttonTextNo: "No",
          title: " ",
        });
        if (isConfirmed) {
          this.isAdmin ? window.close() : this.$router.replace({ name: "interviewLogin" });
        }
      },
      async viewSectionModel() {
        if (!this.isSectionModel) {
          const dataSend = new FormData();
          dataSend.append("infoFinancial.hasAccountBank", this._validateUndefined(this.infoAdmin.hasAccountBank));
          dataSend.append("infoFinancial.accountBank", this.modelHasAccountBank ? this._validateUndefined(this.accountBank) : "");
          dataSend.append("infoFinancial.hasDeclarationRent", this._validateUndefined(this.infoAdmin.hasDeclarationRent));
          dataSend.append("infoFinancial.observations", this._validateUndefined(this.infoAdmin.observations));
          swal.dataSending();
          const endpoint = `${this.$host}interviews/${this.model._id}`;
          const response = await api.post(endpoint, dataSend);
          if (response.data.error) {
            swal.dataSendError({
              message: "Ocurrió algun problema en el servidor y no se pudo guardar la sección de coordinador. Por favor comunicarse con el administrador del sistema.",
            });
          }
          const { data } = await api.get(`${this.$host}interviews/hasinterview?modelId=${this.model._id}`);
          Swal.close();
          if (!data.error) {
            this.model = [];
            this.model = data.model;
            this._initValues(this.model);
          }
          setTimeout(() => {
            this.$global.scrollToEl({ el: "#start_require", behavior: "smooth", topgap: 50 });
          }, 400);
        }
        this.isSectionModel = !this.isSectionModel;
      },
      isChecked(value) {
        return value === "true" ? true : false;
      },
      addCountryPrivacity(value) {
        this.modelCountryPrivacity = value;
      },
      addValueModelsPages(value) {
        this.modelPagesWorked = value.elementsOne;
        this.modelUserWorked = value.elementsTwo;
      },
      addValueStudyName(value) {
        this.modelStudyName = value.elementsOne;
        this.modelReasonBye = value.elementsTwo;
      },
      addValueKnownModel(value) {
        this.modelStudyName = value.elementsOne;
      },
      onlyNumbers(evt, key1, key2) {
        if (!this.$validate.validNumber(evt.key)) {
          this.assignInput(evt, key1, key2);
        }
      },
      handlerCurrency(newValue, oldValue, key) {
        if (newValue) {
          if (newValue.trim() === "$") {
            this[key] = "";
            return;
          }
          if (newValue && !this.$validate.validNumber(this.toNumber(newValue))) {
            this[key] = oldValue;
            return;
          }
          this[key] = this.toCurrency(parseFloat(this.toNumber(newValue)));
        }
      },
      onlyNumbersOneDigit(evt, key1, key2) {
        if (!this.$validate.validNumber(evt.key) || evt.target.value.length > 1) {
          this.assignInput(evt, key1, key2);
        }
      },
      onChangeRadio(evt, key, obj) {
        this.infoAdmin[key] = evt.target.value;
      },
      async onPrint() {
        await this.$router.push({ name: "interviewPrint", params: { photos: this.model.photos } });
      },
      onSelectedDepartamento(value) {
        this.departamento = value;
        if (value !== "") {
          this.$refs.select_depart.$el.classList.remove("error");
          this.$validate.successInput(this.$refs.select_depart.$el);
        }
      },
      onSelectedCity(value) {
        if (value !== "") {
          this.$refs.select_city.$el.classList.remove("error");
          this.$validate.successInput(this.$refs.select_city.$el);
        }
      },
      onHasSons(evt) {
        const fieldSet = evt.target.parentElement.parentElement.parentElement;
        this.modelHasSons = this._validateBoolean(evt.target.value);
        if (this.modelHasSons) fieldSet.setAttribute("show", "true");
        else fieldSet.setAttribute("show", "false");
      },
      onChangeTimeAvailability(evt, key1, keySelect) {
        const { timeAvailabilityPriorityMorning, timeAvailabilityPriorityLate, timeAvailabilityPriorityNight } = this.model.infoWork;
        const keysAvailability = ["timeAvailabilityPriorityMorning", "timeAvailabilityPriorityLate", "timeAvailabilityPriorityNight"];

        if (evt.target.checked) {
          const valuesAvailability = [timeAvailabilityPriorityMorning, timeAvailabilityPriorityLate, timeAvailabilityPriorityNight].filter((value) => value);
          this.model.infoWork[keySelect] = valuesAvailability.length + 1;
        } else {
          const timeAvailability = evt.target.name.split(".").pop();
          Vue.set(this.model.infoWork, timeAvailability, "");
          let valueUnchecked = +this.model.infoWork[keySelect];
          Vue.set(this.model.infoWork, keySelect, "");

          // Reorganizar la prioridad de los horarios
          const timesSorted = keysAvailability
            .filter((time) => this.model.infoWork[time] !== "" && this.model.infoWork[time] !== undefined)
            .sort((a, b) => this.model.infoWork[a] - this.model.infoWork[b]);

          // Aplicar el indice correspondiente dependiendo de la posicion
          timesSorted.forEach((key) => {
            if (this.model.infoWork.hasOwnProperty(key) && this.model.infoWork[key]) {
              let newIndex = valueUnchecked - this.model.infoWork[key];
              if (newIndex < 0) {
                this.model.infoWork[key] = Math.abs(newIndex);
              }
              valueUnchecked = this.model.infoWork[key];
            }
          });
        }

        this.model[keySelect] = this.isAvailablePriority[key1] = !evt.target.checked;
      },

      onHasStudy(evt) {
        this.modelHasStudy = this._validateBoolean(evt.target.value);
      },
      onHasProfesion(evt) {
        this.modelHasProfesion = this._validateBoolean(evt.target.value);
      },
      onHasSurgery(evt) {
        this.modelHasSurgery = this._validateBoolean(evt.target.value);
      },
      onHasExperiencie(evt) {
        const fieldSet = evt.target.parentElement.parentElement.parentElement;
        this.modelHasExperiencie = this._validateBoolean(evt.target.value);
        if (this.modelHasExperiencie) fieldSet.setAttribute("show", "true");
        else fieldSet.setAttribute("show", "false");
      },
      onHasPrivacity(evt) {
        this.modelHasPrivacity = this._validateBoolean(evt.target.value);
      },
      dateLocaleFormat(date) {
        return this.$global.dateLocaleFormat(date);
      },
      toInputDateTime(date) {
        return this.$global.toInputDateTime(date);
      },
      timeAMPM(date) {
        const _date = new Date(date);
        return this.$global.timeAMPM(`${_date.getHours()}:${_date.getMinutes()}`);
      },
      assignInput(evt, key1, key2) {
        const regex = /[^0-9]/g;
        if (key2) {
          this.model[key1][key2] = this.model[key1][key2].replace(regex, "");
        } else if (key1) {
          this.model[key1] = this.model[key1].replace(regex, "");
        }
      },
      toCurrency: function (value) {
        if (typeof value !== "number") {
          return value;
        }
        const formatter = new Intl.NumberFormat("es-CO", {
          style: "currency",
          currency: "COP",
          minimumFractionDigits: 0,
        });
        return formatter.format(value);
      },
      toNumber: function (value) {
        if (!value) {
          return value;
        }
        return value.replace(/[^\d,]/gi, "");
      },
      validateEmpty(evt) {
        if (evt.target.value !== "") {
          this.$validate.successInput(evt.target);
          evt.target.classList.remove("error");
        } else {
          this.$validate.validateInput(evt);
          evt.target.classList.add("error");
        }
      },

      _validateUndefined(value) {
        return !value || value === "undefined" || value === "null" ? "" : value;
      },
      _validateBoolean(value) {
        return value === "true" ? true : false;
      },
      _initValues() {
        if (this.model.documentType) {
          const _documentOld = {
            CC: "idcard",
            "Cédula extrangera": "idforeign",
            Pasaporte: "passport",
          };
          this.model.documentType = _documentOld[this.model.documentType] || this.model.documentType;
        }
        this.modelHasStudy = this.isChecked(this.model.infoPersonal.hasStudy);
        this.modelHasSons = this.isChecked(this.model.infoPersonal.hasSons);
        this.modelHasProfesion = this.isChecked(this.model.infoWork.hasWork);
        this.modelHasExperiencie = this.model.hasWebcam === "yes" ? true : false;
        this.modelStudyName = this.modelHasExperiencie ? this.model.infoWork.modelStudyName ?? [] : [];
        this.modelReasonBye = this.modelHasExperiencie ? this.model.infoWork.modelReasonBye ?? [] : [];
        this.modelPagesWorked = this.modelHasExperiencie ? this.model.infoWork.modelPagesWorked ?? [] : [];
        this.modelUserWorked = this.modelHasExperiencie ? this.model.infoWork.modelUserWorked ?? [] : [];
        this.modelHasPrivacity = this.isChecked(this.model.infoWork.hasPrivacity);
        this.modelHasAccountBank = this.isChecked(this.model.infoFinancial.hasAccountBank);
        this.modelHasSurgery = this.isChecked(this.model.infoAdditional.hasThinkSurgery);
        this.modelCountryPrivacity = this.model.infoWork.countryPrivacity || [];
        this.departamento = this.model.departamento;
        this.isRecommended = this.model.infoAdditional.recommended ? true : false;
        this.isKnowModel = this.model.infoAdditional.knowModel ? true : false;
        this.modelsKnowModels1a = this.model.infoAdditional.modelsKnowModels1a ? this.model.infoAdditional.modelsKnowModels1a.split(",") : [];
        this.dateInterview = this.model.interviewDateSuccess ? this.model.interviewDateSuccess : Date.now();
        this.infoAdmin = this.model.infoFinancial;
        this.infoAdmin.observations = this.infoAdmin.observations !== "undefined" ? this.infoAdmin.observations : "";
        this.accountBank = this.modelHasAccountBank ? this.infoAdmin.accountBank ?? "" : "";
        this.model.infoPersonal.yearsOld = global.yearsDiff(this.model.birthDayDate, Date.now());
        this.isAvailablePriority.morning = this.model.infoWork.timeAvailabilityMorning ? false : true;
        this.isAvailablePriority.late = this.model.infoWork.timeAvailabilityLate ? false : true;
        this.isAvailablePriority.night = this.model.infoWork.timeAvailabilityNight ? false : true;

        this.bestSalaryMonth = this.model.infoWork.bestSalaryMonth ?? "";
        this.bestSalaryWeek = this.model.infoWork.bestSalaryWeek ?? "";
        this.bestSalaryYear = this.model.infoWork.bestSalaryYear ?? "";
        this.salaryOneYear = this.model.infoWork.salaryOneYear ?? "";
        this.salarySixMonth = this.model.infoWork.salarySixMonth ?? "";
        this.salaryTwoMonth = this.model.infoWork.salaryTwoMonth ?? "";
        this.bestSalaryCOP = this.model.infoWork.bestSalaryCOP ?? "";
      },
      /** VALIDACIONES */
      validPhone(evt) {
        if (this.$validate.validPhone(evt.target.value)) {
          evt.target.classList.remove("error");
          this.$validate.successInput(evt.target);
        } else {
          evt.target.classList.add("error");
          this.$validate.errorInput(evt.target);
        }
      },
      validString(evt) {
        if (evt.target.value !== "") {
          evt.target.classList.remove("error");
          this.$validate.successInput(evt.target);
        } else {
          evt.target.classList.add("error");
          this.$validate.errorInput(evt.target);
        }
      },
      validNumber(evt) {
        if (this.$validate.validNumber(evt.target.value)) {
          evt.target.classList.remove("error");
          this.$validate.successInput(evt.target);
        } else {
          evt.target.classList.add("error");
          this.$validate.errorInput(evt.target);
        }
      },
    },
    async beforeMount() {
      await this.$store.dispatch("fetchProfile");
      const user = this.$store.state.userProfile;

      if (user && this.$global.hasSome(["super", "coord"], user.roles)) {
        this.isAdmin = true;
      }
      if (this.$route.query?.sesion !== user?.SSID) {
        this.isAdmin = false;
      }
      this.isAdmin ? (this.isSectionModel = false) : (this.isSectionModel = true);

      const modelId = this.$route.query?.modelId;
      if (modelId) {
        const { data } = await api.get(`${this.$host}interviews/hasinterview?modelId=${modelId}`);
        if (!data.error && (this.isAdmin || data.model?.availableInterview)) {
          this.isAllowed = true;
          this.model = data.model;
          this._initValues(this.model);
          return;
        }
      }

      this.$router.push({ name: "interviewLogin" });
    },
  };
</script>

<style lang="scss">
  @mixin radioButton() {
    margin-right: 8px;
    border: 0.5px solid $primary_color;
    &:checked {
      background-color: $primary-color;
    }
    &:checked:after,
    &:checked:before {
      content: "\f00c";
      font-family: FontAwesome;
      position: absolute;
      font-size: 60%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
    }
    &--checkbox {
      width: 18px;
      height: 18px;
      border: 2px solid $chicago;
      &:checked {
        border: 2px solid $primary-color;
        background-color: $white;
        color: $primary-color;
      }
    }
  }
  .flex {
    @include Flex(row, flex-start, center);
    gap: 5px;
  }
  .error {
    border: 0.5px solid $primary_color;
  }
  .data-error {
    color: $primary_color;
    font-size: 80%;
  }
  *[label="required"] {
    &::after {
      content: "*";
      margin: 0 5px;
      color: $primary_color;
    }
  }
  *[margin="bottom"] {
    margin-bottom: 33px;
  }
  *[margin="top"] {
    margin-top: 16px;
  }

  .interviewView {
    background-color: $white;
  }

  .interview {
    @include Flex(column);
    width: 100%;
    margin: 70px auto;
    color: $chicago;
    background-color: $white;
    input,
    select,
    textarea,
    button {
      color: $chicago;
      font-size: 16px;
      font-family: $first_font;
    }
    button {
      color: $white;
    }
    textarea {
      white-space: pre-line;
    }
    h2,
    h4 {
      font-family: $sec_font;
    }
    &__card {
      width: 96%;
      padding: 8px;
      margin: 24px auto;
      @include Flex(column, flex-start, flex-start);
      border-radius: 8px;
      box-shadow: 2px 2px 16px rgba(0, 0, 0, 0.16);
      &.photos {
        display: none;
      }
    }
    &__title {
      align-self: flex-start;
      font-size: 32px;
      font-weight: lighter;
      color: $primary_color;
    }
    &__subtitle {
      font-weight: lighter;
      font-size: 24px;
      margin-bottom: 20px;
      &--admin {
        margin-bottom: 8px;
        font-size: 22px;
        &[colorTitle] {
          font-size: 24px;
          color: $primary_color;
          margin-bottom: 8px;
        }
      }
    }
    &__labelTitle {
      font-family: $sec_font;
      margin-top: 8px;
    }
    &__labelSingle {
      font-weight: bold;
    }
    &__checkInput {
      padding: 0 8px 0 5px;
      white-space: nowrap;
    }
    &__textArea {
      width: 100%;
      min-height: 160px;
      line-height: 1.5;
      @extend .interview__inputWrite;
    }
    &__inputWrite {
      width: 100%;
      padding: 4px;
      border-radius: 6px;
      border: 0.5px solid $silver;
      &--single {
        border: none;
        border-bottom: 2px solid $silver;
        border-radius: 0;
        width: 100%;
      }
      &.error {
        @extend .error;
      }
    }
    &__fieldSet[show="true"] {
      width: 100%;
      padding: 20px;
      margin: 10px 0;
      border: 1px solid $primary_color;
      border-radius: 13px;
      position: relative;
      &::before {
        content: attr(tittle);
        position: absolute;
        top: -15px;
        left: 24px;
        z-index: 1;
        color: $primary_color;
        font-family: $sec_font;
        font-size: 24px;
        background-color: $white;
      }
    }
    &__container {
      width: 100%;
    }
    &__containerBasic {
      margin: 16px 0;
    }
    &__containerMultipleInput {
      @include Flex(column, flex-start, flex-start);
      width: 100%;
      margin: 8px 0;
      gap: 8px;
    }
    &__containerSelects,
    &__containerInput {
      @include Flex(column, flex-start, flex-start);
      width: 100%;
      margin: 8px 0;
      max-width: 768px;
      .mycheck--checkbox {
        width: 18px;
        height: 18px;
        min-width: 18px;
        min-height: 18px;
        border: 2px solid $chicago;
        &:checked {
          border: 2px solid $primary-color;
          background-color: $white;
          color: $primary-color;
        }
      }
      &.special {
        margin: 8px 0;
        justify-content: flex-start;
        .cl {
          width: auto;
          padding-right: 5px;
        }
        .interview__inputWrite--single {
          width: 100%;
        }
      }
      &.title {
        width: auto;
        max-width: 768px;
        margin: 24px;
      }
    }
    &__socialNetwork {
      margin-bottom: 5px;
      .interview__labelTitle {
        margin: 0;
      }
    }
    &__parraphe {
      margin-top: 8px;
      font-family: $sec_font;
    }
    &__subContainerSelects {
      width: 100%;
      margin: 8px 0;
    }
    &__subContainerInputDouble {
      display: grid;
      grid-template-columns: 50% 50%;
    }
    &__radioSectionsTable {
      width: 100%;
      @include Flex(column, flex-start, flex-start);
      gap: 8px;
      margin: 8px 0;
    }

    &__radioSectionRow {
      @include Flex(column, flex-start, flex-start);
      gap: 4px;
      width: 100%;
      padding: 4px;
      background-color: $hawkes_blue;
      &--head {
        background-color: initial;
      }
      .firstColumn {
        width: 100%;
      }
      .head {
        width: 24px;
        height: 24px;
        margin: 0 1px;
        text-align: center;
      }
      .mycheck {
        @include radioButton();
        margin: 0 1px;
      }
    }
    &__radioSectionRowCheck {
      @include Flex(row, flex-end);
      gap: 4px;
      width: 100%;
    }
    &__inputNumberShort {
      width: 59px;
      height: 29px;
      padding: 8px;
      margin-left: 15px;
    }
    &__inputYear {
      width: 40px;
      margin: 4px 8px 0 0;
      padding: 4px;
      border-radius: 6px;
      border: 0.5px solid $silver;
    }
    &__radioSections {
      @include Flex(column, flex-start, flex-start);
      .container__sons {
        display: flex;
        margin-top: 10px;
        flex-direction: column;
        &--age {
          margin-left: 15px;
          font-weight: bold;
        }
      }
      margin: 8px 0;
      .container {
        @include Flex(row);
        margin: 8px 0;
      }
      .mycheck {
        @include radioButton();
      }
    }
    &__submit {
      appearance: none;
      @include Flex(row);
      width: 179px;
      height: auto;
      margin: auto;
      @include ButtonStyle();
      background-color: $primary_color;
      border: none;
      cursor: pointer;
      &:hover {
        opacity: 0.7;
      }
      &.inputSubmit,
      &.inputBack,
      &.inputCancel {
        color: $white;
      }
      &.inputCancel {
        background-color: $emperor;
      }
      &.print {
        display: none;
        color: $white;
        width: auto;
        padding: 10px;
      }
    }
    .containterButtons {
      margin: 8px auto;
      width: auto;
      justify-content: center;
      flex-direction: column;
      gap: 15px;
    }
    &__containerCheck {
      @include Flex(row, flex-start);
    }
    &__photos {
      width: 100%;
      margin: 16px 0;
    }
    &__link {
      width: 100%;
      margin: auto;
      margin-top: $mpadding;
      @include Flex(row);
      gap: $mpadding/3;
      font-weight: bold;
      color: $primary_color;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
    @media screen and (min-width: $mobile_width) {
      &__card {
        max-width: $tablet_width;
        padding: 16px 36px;
      }
      .containterButtons {
        flex-direction: row;
      }
      .interview__submit {
        margin: 0;
      }
      &__radioSectionRowCheck {
        gap: 8px;
      }
    }
    .interview__socialNetworks {
      width: 100%;
      display: flex;
      flex-direction: column;
    }
    &__subContainerGroup {
      width: 100%;
      &--row {
        @include Flex(row, flex-start);
        gap: 8px;
      }
      .social-btn {
        border-radius: 100px;
      }
      margin: 8px 0 16px 0;
      .interview__inputArroba {
        display: flex;
        height: 40px;
        span {
          display: grid;
          place-items: center;
          width: 35px;
          background: #bfbfbf;
          color: #fff;
          font-weight: bold;
          border-radius: 6px 0 0 6px;
        }
        input {
          border-radius: 0 10px 10px 0;
        }
      }
    }
    @media screen and (min-width: $pocket_width) {
      &__radioSectionRow {
        flex-direction: row;
      }
    }
    @media screen and (min-width: $tablet_width) {
      width: 100%;
      margin: 70px auto;
      &__card {
        width: $tablet_width;
        padding: 32px 72px;
        margin: 24px;
        .interview__socialNetworks {
          width: 100%;
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: 25px;
          .interview__subContainerGroup {
            margin: 0;
          }
        }
      }
      &__subContainerGroup {
        .interview__inputArroba {
          height: initial;
        }
      }
      &__labelCheck {
        width: 165px;
      }
      &__labelText {
        width: auto;
        word-break: normal;
      }
      &__labelSingle {
        width: 18%;
        &--bank {
          width: 40%;
        }
        &.goals {
          width: 12%;
        }
      }

      &__inputWrite {
        padding: 8px;
      }
      &__radioSectionRow {
        align-items: center;
        .head {
          margin: 0 11px;
        }
        .mycheck {
          margin: 0 11px;
        }
        .firstColumn {
          width: 30%;
        }
      }
      &__radioSectionRowCheck {
        gap: 3px;
      }
      &__container {
        width: auto;
      }
      &__containerSelects,
      &__containerInput {
        @include Flex(row, space-between);
        gap: 8px;
        &.special {
          margin: 8px 0;
          justify-content: flex-start;
          .cl {
            padding-right: 5px;
          }
          .interview__inputWrite--single {
            margin: 0 5px;
            width: 100%;
          }
        }
      }
      &__subContainerInputDouble {
        grid-template-columns: 48% 52%;
      }
      &__subContainerSelects {
        width: 49%;
      }
      &__submit {
        &.print {
          border-radius: 8px;
          display: flex;
        }
      }
    }
    @media print {
      display: block;
      width: 100%;
      margin: 70px auto;
      &__container {
        margin: 0;
      }
      &__containerSelects,
      &__containerInput,
      &__containerMultipleInput {
        display: block;
      }
      &__card {
        display: block;
        width: auto;
        max-width: $tablet_width;
        padding: 32px 72px;
        &.photos {
          display: block;
          width: 70%;
          margin: auto;
        }
      }
      &__labelCheck {
        width: 165px;
      }
      &__labelText {
        width: 70px;
      }
      &__title {
        margin: 0;
      }

      &__inputWrite {
        padding: 8px;
        margin: 4px 0 8px 0;
      }
      &__inputYear {
        padding: 8px;
        width: 142px;
        margin: 4px 8px 0 0;
      }
      &__radioSectionRow {
        flex-direction: row;
        .head {
          margin: 0 11px;
        }
        .mycheck {
          margin: 0 11px;
        }
      }
    }
  }
</style>
